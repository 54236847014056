"use strict";

window.addEventListener('load', function () {
  var header = document.querySelector('header');
  var headerLogo = document.querySelector('header .logo-wrapper img');
  var main = document.querySelector('main');
  window.addEventListener('scroll', toggleHeaderSize);

  function toggleHeaderSize() {
    console.log(window.scrollY);

    if (window.scrollY > 100) {
      header.classList.add('fixed');
      headerLogo.classList.add('hidden');
      main.classList.add('fixed-header');
    } else {
      header.classList.remove('fixed');
      headerLogo.classList.remove('hidden');
      main.classList.remove('fixed-header');
    }
  }

  var menuIcon = document.querySelector('.menu-icon');

  if (menuIcon) {
    menuIcon.addEventListener('click', toggleMenuIcon);
  }

  function toggleMenuIcon(event) {
    event.stopPropagation();
    var menuIconImg = this.querySelector('.ham');
    var navContainer = document.querySelector('.header .nav-container');
    this.classList.toggle('menu-is-visible');
    menuIconImg.classList.toggle('active');
    navContainer.classList.toggle('visible');
  }

  var menuItems = document.querySelectorAll('.header .menu-item a');

  if (menuItems.length !== 0) {
    menuItems.forEach(function (menuItem) {
      menuItem.addEventListener('click', closeMenu);
    });
  }

  var navContainer = document.querySelector('.header .nav-container');

  if (navContainer !== null) {
    navContainer.addEventListener('click', closeMenu);
  }

  var body = document.querySelector('body');
  body.addEventListener('click', function () {
    if (document.querySelector('.header .nav-container').classList.contains('visible')) {
      closeMenu();
    }
  });

  function closeMenu() {
    var menuIconImg = document.querySelector('.ham');
    var navContainer = document.querySelector('.header .nav-container');
    var menuIcon = document.querySelector('.menu-icon');
    menuIcon.classList.toggle('menu-is-visible');
    menuIconImg.classList.toggle('active');
    navContainer.classList.toggle('visible');
  }

  var mapSectionDetailsBtn = document.querySelector('.map-wrapper .btn-map');
  var mapSectionDetailsCloseBtn = document.querySelector('.map-container .btn-details-close');

  if (mapSectionDetailsCloseBtn) {
    mapSectionDetailsCloseBtn.addEventListener('click', hideMapDetailsPopup);
  }

  if (mapSectionDetailsBtn) {
    mapSectionDetailsBtn.addEventListener('click', showMapDetailsPopup);
  }

  function showMapDetailsPopup() {
    var mapDetailsPopup = document.querySelector('.map-container .details-popup');

    if (mapDetailsPopup) {
      mapDetailsPopup.classList.add('visible');
    }
  }

  function hideMapDetailsPopup() {
    var mapDetailsPopup = document.querySelector('.map-container .details-popup');

    if (mapDetailsPopup) {
      mapDetailsPopup.classList.remove('visible');
    }
  } // ----- VIDEO POPUP -----------


  var videoBtnStart = document.querySelector('.map-container .mfpopup-btn');

  if (videoBtnStart) {
    videoBtnStart.addEventListener('click', startVideo);
  }

  function startVideo(event) {
    var popupHref = event.target.getAttribute('href');

    if (popupHref) {
      var video = document.querySelector(popupHref + ' video');

      if (video) {
        var videoSrc = video.querySelector('source').getAttribute('src');

        if (videoSrc) {
          video.play();
        }
      }
    }
  }

  $('.mfpopup-btn').magnificPopup({
    type: 'inline',
    midClick: true,
    // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
    removalDelay: 250,
    //delay removal by X to allow out-animation
    callbacks: {
      beforeOpen: function beforeOpen() {
        this.st.mainClass = this.st.el.attr('data-effect');
      },
      close: function close() {
        var video = document.getElementById('map-section-video');
        video.pause();
      }
    }
  }); // -------- FORM POPUP -----------

  $('.popup-with-form').magnificPopup({
    type: 'inline',
    preloader: false,
    focus: '#name',
    // When elemened is focused, some mobile browsers in some cases zoom in
    // It looks not nice, so we disable it:
    callbacks: {
      beforeOpen: function beforeOpen() {
        if ($(window).width() < 700) {
          this.st.focus = false;
        } else {
          this.st.focus = '#name';
        }
      }
    }
  }); //--------------------------------------------

  $('.cottage-city-slides').slick({
    prevArrow: '<button class="btn-arrow prev-arrow"></button>',
    nextArrow: '<button class="btn-arrow next-arrow"></button>',
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    responsive: [{
      breakpoint: 1025,
      settings: {
        arrows: false,
        dots: true
      }
    }]
  });
  $('.cottages-slides').slick({
    prevArrow: '',
    nextArrow: '<button class="btn-arrow next-arrow anim-pulsate"></button>',
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear'
  });
  $('.single-cottage-gallery').slick({
    prevArrow: '',
    nextArrow: '<button class="btn-arrow next-arrow anim-pulsate"></button>',
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear'
  }); // ---- intersection observer for single post scroll animation

  var options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1
  };

  var callback = function callback(entries, observer) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        entry.target.classList.add('io-fadein');
        observer.unobserve(entry.target);
      }
    });
  };

  var observer = new IntersectionObserver(callback, options);
  var elements = document.querySelectorAll('' + '.footer *, ' + '#leaves-3 *:not(.btn-primary), ' + '#leaves-4 *:not(.btn-primary), ' + '.hero *, ' + '.map *:not(.details-popup), ' + '#cottage-city *:not(.btn-arrow), ' + '#cottages * , ' + '#application *, ' + '.section-gradient *, ' + '.page-title, ' + '.marquee, ' + '.callback-section, ' + '.page-template-tpl-commissioning .page-single-section *');

  if (elements.length !== 0) {
    elements.forEach(function (el) {
      observer.observe(el);
    });
  }
});